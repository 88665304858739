import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./LanguageSwitcher.module.css"; // استدعاء CSS للـ Language Switcher

function LanguageSwitcher() {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const currentLang = i18n.language;
    const newLang = currentLang === "en" ? "ar" : "en";
    i18n.changeLanguage(newLang);

    // تغيير اتجاه النص مع الحفاظ على شريط التمرير في اليمين
    document.body.setAttribute("dir", newLang === "ar" ? "rtl" : "ltr");
    document.documentElement.style.direction = "ltr"; // تثبيت شريط التمرير على اليمين
  };

  // تعيين اللغة الافتراضية عند تحميل التطبيق
  useEffect(() => {
    const currentLang = i18n.language;

    // تغيير اتجاه النص مع الحفاظ على شريط التمرير في اليمين
    document.body.setAttribute("dir", currentLang === "ar" ? "rtl" : "ltr");
    document.documentElement.style.direction = "ltr"; // تثبيت شريط التمرير على اليمين
  }, [i18n]);

  return (
    <div className={styles.container}>
      <button className={styles.icon} onClick={toggleLanguage}>
        {i18n.language === "en" ? "AR" : "EN"}
      </button>
    </div>
  );
}

export default LanguageSwitcher;
