// import React from 'react';
// import styles from './FAQ.module.css'; // استيراد الأنماط من CSS Module
// import { useTranslation } from 'react-i18next';

// const FAQItem = ({ question, answer }) => {
  
//   const [isOpen, setIsOpen] = React.useState(false);

//   const toggleAnswer = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <div className={styles.faqItem}>
//       <div className={styles.faqQuestion} onClick={toggleAnswer}>
//         <h3>{question}</h3>
//         <span>{isOpen ? '-' : '+'}</span>
//       </div>
//       {isOpen && <div className={styles.faqAnswer}><p>{answer}</p></div>}
//     </div>
//   );
// };

// const FAQ = () => {
//   const { t } = useTranslation();
//   const faqData = [
//     { question: t('faq.question1'), answer: t('faq.answer1') },
//     { question: t('faq.question2'), answer: t('faq.answer2') },
//     { question: t('faq.question3'), answer: t('faq.answer3') },
//     { question: t('faq.question4'), answer: t('faq.answer4') },
//     { question: t('faq.question5'), answer: t('faq.answer5') },
//     { question: t('faq.question6'), answer: t('faq.answer6') },
//     { question: t('faq.question7'), answer: t('faq.answer7') },
//     { question: t('faq.question8'), answer: t('faq.answer8') },
//     { question: t('faq.question9'), answer: t('faq.answer9') },
//     { question: t('faq.question10'), answer: t('faq.answer10') },
//   ];
  

//   return (
//     <div className={styles.faqContainer}>
//       <h2>{ t('faq.faq')}</h2>
//       {faqData.map((item, index) => (
//         <FAQItem key={index} question={item.question} answer={item.answer} />
//       ))}
//     </div>
//   );
// };

// export default FAQ;


import React from 'react';
import styles from './FAQ.module.css'; // استيراد الأنماط من CSS Module
import { useTranslation } from 'react-i18next';

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className={styles.faqItem}>
      <div className={styles.faqQuestion} onClick={onClick}>
        <h3>{question}</h3>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className={styles.faqAnswer}><p>{answer}</p></div>}
    </div>
  );
};

const FAQ = () => {
  const { t } = useTranslation(); 
  const [openIndex, setOpenIndex] = React.useState(null);

  const faqData = [
    { question: t('faq.question1'), answer: t('faq.answer1') },
    { question: t('faq.question2'), answer: t('faq.answer2') },
    { question: t('faq.question3'), answer: t('faq.answer3') },
    { question: t('faq.question4'), answer: t('faq.answer4') },
    { question: t('faq.question5'), answer: t('faq.answer5') },
    { question: t('faq.question6'), answer: t('faq.answer6') },
    { question: t('faq.question7'), answer: t('faq.answer7') },
    { question: t('faq.question8'), answer: t('faq.answer8') },
    { question: t('faq.question9'), answer: t('faq.answer9') },
    { question: t('faq.question10'), answer: t('faq.answer10') },
  ];

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className={styles.faqContainer}>
      <h2>{t('faq.faq')}</h2>
      {faqData.map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
          isOpen={openIndex === index}
          onClick={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default FAQ;
