import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import LanguageSwitcher from "../../00-Main/LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from 'react-i18next'; // Importing the translation hook

const Header = () => {
  const { t } = useTranslation(); // Using the translation function
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to the document
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // Close menu when clicking outside or scrolling
  useEffect(() => {
    const handleOutsideClickOrScroll = () => {
      if (isMenuOpen) {
        closeMenu();
      }
    };

    // Listen for clicks and scroll events
    document.addEventListener("click", handleOutsideClickOrScroll);
    window.addEventListener("scroll", handleOutsideClickOrScroll);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("click", handleOutsideClickOrScroll);
      window.removeEventListener("scroll", handleOutsideClickOrScroll);
    };
  }, [isMenuOpen]);

  return (
    <header className="header" onClick={(e) => e.stopPropagation()}>
      {/* <LanguageSwitcher/> */}
      <div className="logo">
        <Link to="/" onClick={closeMenu}>
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="logo" />
        </Link>
        <h4 className="logo-text">House Technology Trading</h4>
      </div>
      <div className="warranty">
        <img  src={ `${process.env.PUBLIC_URL}/images/0000.png` } alt="" />
        <p>{t('warranty.text') }</p>
      </div>

      <div
        className={`hamburger ${isMenuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>

      <nav className={`header-list ${isMenuOpen ? "active" : ""}`}>
        <li>
          <Link to="/" onClick={closeMenu}>
            {t('header.home')}
          </Link>
        </li>
        <li>
          <Link to="/products" onClick={closeMenu}>
            {t('header.products')}
          </Link>
        </li>
        <li>
          <Link to="/Contact" onClick={closeMenu}>
            {t('header.contact')}
          </Link>
        </li>
        <li>
          <Link to="/about" onClick={closeMenu}>
            {t('header.about')}
          </Link>
        </li>
        {/* <li>
          <Link to="/offer" onClick={closeMenu}>
            {t('header.offer')}
          </Link>
        </li> */}
        <li>
          <Link to="/faq" onClick={closeMenu}>
          {t('faq.faq')}
          </Link>
        </li>
        <li>
        
        {/* <LanguageSwitcher/> */}
          
        
        </li>
      </nav>
    </header>
  );
};

export default Header;



