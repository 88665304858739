import React from "react";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
// import { FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import styles from "./ContactIcons.module.css"; // ملف CSS Modules الخاص بالمكون
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";

const ContactIcons = () => {
  return (
    <div className={ styles.container }>
       <LanguageSwitcher />
      <a href="tel:+971555895194" className={styles.icon} title="اتصل بنا">
        <FaPhoneAlt size={20} />
      </a>
      <a
        href="https://wa.me/971589445296"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.icon}
        title="تواصل عبر واتساب"
      >
        <FaWhatsapp size={26} />
      </a>
       
    </div>
  );
};

export default ContactIcons;
